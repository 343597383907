export default {
  install: (Vue) => {
    [{
        name: 'PageBread',
        component: () => import('./pageBread.vue')
      },
      {
        name: 'PanelList',
        component: () => import('./panelList.vue')
      },
      {
        name: 'NqForm',
        component: () => import('./nqForm.vue')
      },
      {
        name: 'countrySelect',
        component: () => import('./countrySelect.vue')
      },
      {
        name: 'nqDialog',
        component: () => import('./nqDialog.vue')
      },
      {
        name: 'pagination',
        component: () => import('./pagination.vue')
      },
      {
        name: 'confirm',
        component: () => import('./Confirm.vue')
      },
      {
        name: 'PageTab',
        component: () => import('./PageTab.vue')
      },
      {
        name:'ScrollView',
        component:() => import('./scrollView.vue')
      }
    ].forEach(item => {
      Vue.component(item.name, item.component)
    })
  }
}