import Vue from 'vue'
import VueRouter from 'vue-router'
import routerList from './router-pages.js'
import baseInfo from '../utils/config'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: routerList
})
const whiteList = baseInfo.ROTER_WHITE_LIST
if (baseInfo.ROUTER_INTERCEPT) {
  router.beforeEach((to, from, next) => {
    
    if (localStorage.userInfo) {
      if (to.path === '/login' && baseInfo.IS_CF==0) {
        next({
          path: '/'
        })
      }
      next()
    } else {
      if (whiteList.indexOf(to.path) !== -1 || to.meta.noFilter) {
        next()
      } else {
        next('/login')
      }
    }
  })
}
export default router