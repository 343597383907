<template>
  <div class="page">
    <HeaderBar v-if="!hideFooterAndHeader"></HeaderBar>
    <div class="page-main">
      <router-view></router-view>
    </div>
    
    <FooterBar v-if="!hideFooterAndHeader"></FooterBar>
  </div>
</template>
<script>
import HeaderBar from '@/layout/HeaderBar'
import FooterBar from '@/layout/FooterBar'
export default {
  name: 'Home',
  components: {
    HeaderBar,
    FooterBar
  },
  data() {
    return {
      hideFooterAndHeader: false,
      showUpdateAccount: false
    }
  },
  watch: {
    $route: function(val) {
      this.hideFooterAndHeader = val.meta.fullPage
    }
  },
  created() {},
  methods: {}
}
</script>
<style lang="less" scoped>

</style>
