<template>
  <div class="header-bar padding-10">
    <div class="header-left" @click="menuShowOrHide">
      <img src="../assets/image/list.png" class="header-img"  v-if="$baseInfo.IS_CF!==1"/>
    </div>
    <div class="header-center" >
      <img src="../assets/image/logo.png" class="header-img" v-if="$baseInfo.IS_CF!==1"/>
    </div>
    <div class="header-right" @click="$router.push($baseInfo.IS_CF!==1?'/login':'/user-center')">
      <img src="../assets/image/client.png" class="header-img" />
    </div>
    <div class="article-menus animate" :style="'left:' + menuPostion + 'px'">
      <ul>
        <li @click="toPage('/article-list/9/物流常识')">
          <img src="../assets/image/index1.png" alt="" />
          物流常识
        </li>
        <li @click="toPage('/article-list/10/常见国家')">
          <img src="../assets/image/index2.png" alt="" />常见国家
        </li>
        <li @click="toPage('/article-list/11/公司动态')">
          <img src="../assets/image/index5.png" alt="" />公司动态
        </li>
        <li @click="toPage('/article-list/8/优惠活动')" >
          <img src="../assets/image/index7.png" alt="" />优惠活动
        </li> 
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerBar",
  data() {
    return {
      menuPostion: -300,
    };
  },
  methods: {
    menuShowOrHide() {
      if (this.menuPostion === -300) {
        this.menuPostion = 0;
      } else {
        this.menuPostion = -300;
      }
    },
    toPage(url) {
      this.$router.push(url);
      this.menuPostion = -300;
    },
  },
};
</script>
<style scoped lang="less">
.header-center {
  flex: 1;
  text-align: center;
}
.header-img {
  height: 55px;
}
.article-menus {
  position: fixed;
  top: 60px;
  left: 0px;
  width: 180px;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 0px 8px #dedede;
  li {
    border-bottom: 1px solid #dedede;
    display: flex;
    padding: 8px 10px;
    align-items: center;
    img {
      width: 30px;
    }
  }
}
</style>