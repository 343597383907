<template>
  <div id="app" class="page">
    <Home></Home>
  </div>
</template>

<script>
import Home from "@/pages/Home";
export default {
  name: "App",
  components: { Home },
  data() {
    return {};
  },
};
</script>
<style lang="less">
@import url("@/assets/style/public.less");
</style>
