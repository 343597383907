import devConf from '../../devConfig'
let baseInfo = window.ck_pro_production_config_info
if (process.env.NODE_ENV === 'development') {
  baseInfo = devConf
}
 

export default baseInfo

// 参考地址：https://rgqh9u.axshare.com/
// 老网址：http://192.168.31.246:81/manager/default.aspx