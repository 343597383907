import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import less from 'less'
import baseInfo from './utils/config'
import store from '../src/store/index.js'
import CkElementUI from './common/componentsInstall.js'
import scroll from 'vue-seamless-scroll'
import confirm from '@/common/Confirm.js'
import toast from '@/common/Toast.js'
import {is_weixn} from './utils/publicFun'
import {
  reqGet,
  reqPost,
  reqPut,
  reqDelete
} from './utils/request'


Vue.config.productionTip = false
Vue.use(less)
Vue.use(CkElementUI)
Vue.prototype.$reqGet = reqGet
Vue.prototype.$reqPost = reqPost
Vue.prototype.$reqPut = reqPut
Vue.prototype.$reqDelete = reqDelete
Vue.prototype.$baseInfo = baseInfo
Vue.prototype.$toast = toast
Vue.prototype.$confirm = confirm
Vue.prototype.$isWeChat = is_weixn()
Vue.use(scroll)

// 添加自定义指令
// 注册一个全局自定义指令 `v-focus`
Vue.directive('loading', {
  // 当被绑定的元素插入到 DOM 中时……
  update: function (el,binding,vnode) {
    if (binding.value) {
      el.classList.add('loading-status')
    }
    if (!binding.value) {
      el.classList.remove('loading-status')
    }
  }
})

Vue.directive('wait', {
  // 当被绑定的元素插入到 DOM 中时……
  update: function (el,binding,vnode) {
    if (binding.value) {
      console.log(binding.value)
      el.classList.add('wait-status')
    }
    if (!binding.value) {
      console.log(binding.value)
      el.classList.remove('wait-status')
    }
  }
})
document.title = baseInfo.BASE_TITLE
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')