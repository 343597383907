export default [
  {
    path: '/',
    title: '牛气集运',
    component: () => import('../pages/index/index.vue'),
    meta: { transition: 'slide-right', noFilter: true }
  },
  {
    path: '/weixin-login',
    meta: { fullPage: true },
    title: '授权登录',
    component: () => import('../pages/weChatLogin.vue')
  },
  {
    path: '/copy-login/:tab',
    title: '授权登录2',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/copyWeChatLogin.vue')
  },
  {
    path: '/copy-info/:tab',
    title: '授权登录3',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/copyWeixinGetInfo.vue')
  },
  {
    path: '/login',
    title: '牛气集运-登录',
    component: () => import('../pages/login/index.vue')
  },
  {
    path: '/user-center',
    title: '牛气集运-会员中心',
    component: () => import('../pages/user-center/index.vue'),
    meta: { transition: 'slide-right', noFilter: true }
  },
  {
    path: '/order-management/:tab',
    title: '牛气集运-用户后台',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/order-management/index.vue')
  },
  {
    path: '/pack',
    title: '我的仓库-查看',
    component: () => import('../pages/order-management/pack.vue')
  },
  {
    path: '/payment',
    title: '已付款-查看',
    component: () => import('../pages/order-management/payment.vue')
  },
  {
    path: '/submit',
    title: '我的仓库-打包',
    component: () => import('../pages/order-management/submit.vue')
  },
  {
    path: '/logistics',
    title: '我的仓库-物流轨迹',
    component: () => import('../pages/order-management/logistics.vue')
  },
  {
    path: '/prediction',
    title: '我的仓库-到货预报',
    component: () => import('../pages/order-management/prediction.vue')
  },
  {
    path: '/warehousing',
    title: '会员中心-入库记录',
    component: () => import('../pages/warehousing/index.vue')
  },
  {
    path: '/transaction',
    title: '会员中心-交易记录',
    component: () => import('../pages/transaction/index.vue')
  },
  {
    path: '/integral',
    title: '会员中心-积分商城',
    meta: {
      fullPage: true
    },
    component: () => import('../pages/integral/index.vue')
  },
  {
    path: '/discount/:id',
    title: '积分商城-积分兑换',
    meta: {
      fullPage: true
    },
    component: () => import('../pages/integral/discount.vue')
  },
  {
    path: '/account',
    title: '会员中心-账户安全',
    component: () => import('../pages/account/index.vue')
  },
  {
    path: '/message',
    title: '账户安全-修改密码',
    component: () => import('../pages/account/message.vue')
  },
  {
    path: '/telNum',
    title: '账户安全-联系方式',
    component: () => import('../pages/account/telNum.vue')
  },
  {
    path: '/address',
    title: '地址簿管理',
    component: () => import('../pages/address/index.vue')
  },
  {
    path: '/address-add',
    title: '新增地址',
    component: () => import('../pages/address/add.vue')
  },
  {
    path: '/address-eidt/:id',
    title: '编辑地址',
    component: () => import('../pages/address/add.vue')
  },
  {
    path: '/fee-compute',
    title: '费用估算',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/fee-compute/index.vue')
  },
  {
    path: '/order-search',
    title: '订单查询',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/order-search/index.vue')
  },
  {
    path: '/liucheng',
    title: '下单流程',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/liucheng/index.vue')
  },
  {
    path: '/contact-us',
    title: '联系我们',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/contact-us/index.vue')
  },
  {
    path: '/article-list',
    title: '文章列表',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/article/index.vue')
  },
  {
    path: '/article-list/:id/:name',
    title: '文章列表',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/article/index.vue')
  },
  {
    path: '/article-detail/:id',
    title: '文章详情',
    meta: { transition: 'slide-right', noFilter: true },
    component: () => import('../pages/article/detail.vue')
  },
  {
    path: '/coupon',
    title: '会员中心-优惠券',
    component: () => import('../pages/coupon/index.vue')
  },
  {
    path: '/screen',
    title: '会员中心-优惠券',
    component: () => import('../pages/bigScreen.vue')
  },
  {
    path: '/parcel',
    title: '会员中心-包裹认领',
    component: () => import('../pages/parcel/index.vue')
  },
  {
    path: '/youhuiquan',
    title: '会员中心-领取优惠券',
    component: () => import('../pages/youhuiquan/index.vue')
  },
  {
    path: '/to-package',
    title: '提交打包',
    component: () => import('../pages/toPackage/index.vue')
  },
  {
    path: '/to-pay/:id/:orderNo',
    title: '去付款',
    component: () => import('../pages/toPay/index.vue')
  },
  {
    path: '/inspection/:orderNum',
    title: '待验货',
    component: () => import('../pages/order-management/inspection.vue')
  },
  {
    path: '/extension/:id',
    title: '我的推广',
    component: () => import('../pages/order-management/extension.vue')
  },
  {
    path: '/qrcode-current',
    title: '二维码',
    meta: { fullPage: true },
    component: () => import('../pages/qrcodeWechat.vue')
  }
]
