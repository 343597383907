import axios from 'axios'
import config from '../utils/config'
// 创建axios实例
const baseUrl = config.BASE_URL
const requestTimeout = config.REQUEST_TIMEOUT
const service = axios.create({
  baseURL: baseUrl,
  timeout: requestTimeout // 请求超时时间
})

// 请求拦截拦截器
service.interceptors.request.use(
  config => {
    if (localStorage.userInfo && JSON.parse(localStorage.userInfo).token && JSON.parse(localStorage.userInfo).id) {
      config.headers.userId = JSON.parse(localStorage.userInfo).id
      config.headers.token = 'mobile-client-token'
      // config.headers.token = JSON.parse(localStorage.userInfo).token
    }
    // if (config.url !== '/appLogin/webRegistreUser' && config.url !== '/appLogin/webUserLogin' && config.url !== '/appLogin/getWxAddressLogin' && config.url.indexOf('/appLogin/url') ==-1 )
    //  {
    //   if (localStorage.userInfo && JSON.parse(localStorage.userInfo).token && JSON.parse(localStorage.userInfo).id) {
    //     config.headers.userId = JSON.parse(localStorage.userInfo).id
    //     config.headers.token = JSON.parse(localStorage.userInfo).token
    //   } else {
    //     location.href = '/login'
    //     localStorage.removeItem('userInfo')
    //   }
    // }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
//响应拦截
service.interceptors.response.use(
  res => {
    if (res.status === 200) {
      return res
    }
  },
  // 请求失败情况
  error => {
    const {
      response
    } = error
    if (response) {
      // 若状态码为 510 或者 401， 跳转至登录界面
      if (response.status === 510 || response.status === 401 || response.status === 512) {
        localStorage.userInfo = ''
        location.href = '/login'
      }
      return Promise.reject(response)
    } else {
      if (error.message) { 
        alert(error.message)
      } else {
        message('服务故障，请检查！');
      }
      return Promise.resolve()
    }
  })
// GET 请求
export const reqGet = (url, data, otherInfo) => {
  return service({
    url: url,
    method: 'get',
    params: data || '',
    ...otherInfo
  })
}

// POST请求
export const reqPost = (url, data, otherInfo) => {
  return service({
    url: url,
    method: 'post',
    data: data || null,
    ...otherInfo
  })
}
// PUT请求
export const reqPut = (url, data, otherInfo) => {
  return service({
    url: url,
    method: 'put',
    data: data || null,
    ...otherInfo
  })
}
// DELETE请求
export const reqDelete = (url, data, otherInfo) => {
  return service({
    url: url,
    method: 'delete',
    data: data || null,
    ...otherInfo
  })
}

// export default service